import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { toast } from "react-toastify";
import HomeContent from "./pages/Home";
import NotFound from "./pages/NotFound";

// Middleware component to handle redirection logic
const Home = () => {
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const uniqueId = searchParams.get('q');

  useEffect(() => {
    const handleRedirect = async () => {
      if (uniqueId) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_DEV_API}/v1/job/unique-id/${uniqueId}`
          );
          const data = await response.json();

          if (response.status === 404 || response.status === 400) {
            toast.error(data.message || "Job not found");
          } else if (data.data && data.data.url) {
            window.location.href = data.data.url;
            return;
          }
        } catch (error) {
          toast.error("An unexpected error occurred.");
        }
      }
    };

    handleRedirect();
  }, [navigate, uniqueId]);

  return <HomeContent />;
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;
